import { TableRow } from "@mui/material";

import { StyledTableCell } from "../../../../../Common/styles/table";

const DataRow = ({ data }: any) => {
  return (
    <TableRow>
      <StyledTableCell>{data?.category || "-"}</StyledTableCell>
      {/* <StyledTableCell>{data?.firstCons}</StyledTableCell>
          <StyledTableCell>{data?.secondCons}</StyledTableCell>
          <StyledTableCell>{data?.lastCons}</StyledTableCell> */}
      <StyledTableCell
        sx={{
          color: !isNaN(data?.value)
            ? data?.value > data?.median
              ? "#31C48D"
              : data?.value === data?.median
              ? "#FFC20A"
              : "#F05252"
            : "inherit",
        }}
      >
        {data?.value ?? "-"}
      </StyledTableCell>
      <StyledTableCell>
        {data?.median ?? ""} ({data?.firstQuart ?? ""} -{" "}
        {data?.thirdQuart ?? ""})
      </StyledTableCell>
    </TableRow>
  );
};

export default DataRow;
