import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import { errorToastMessage } from "../../../../../../utils/toast";
import http from "../../../../../../utils/http";
import DataAccordian from "./DataAccordian";

const AccMap = ["frequency", "intensity", "time"];

const PassiveData = () => {
  const { id: participantId } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const aggrRes: AxiosResponse = await http.get(
          `/passive-data?participantId=${participantId}&type=aggregated_values`
        );
        const convRes: AxiosResponse = await http.get(
          `/passive-data?participantId=${participantId}&type=conversations_values`
        );
        const featRes: AxiosResponse = await http.get(
          `/passive-data?participantId=${participantId}&type=features_values`
        );
        const eduRes: AxiosResponse = await http.get(
          `/passive-data?participantId=${participantId}&type=education_lessons_values`
        );

        const aggrData = aggrRes.data?.data;
        const convData = convRes.data?.data;
        const featData = featRes.data?.data;
        const eduData = eduRes.data?.data;

        let frequency: any = null;
        let intensity: any = {
          educationModule: [],
          conversation: [],
          other: [],
        };
        let time: any = { educationModule: [], conversation: [], other: [] };

        if (!isNaN(aggrData?.value?.numLogins)) {
          frequency = {
            category: "Number of logins",
            value: aggrData?.value?.numLogins,
            median: aggrData?.median?.freqMedian,
            firstQuart: aggrData?.median?.freqFirstQuartile,
            thirdQuart: aggrData?.median?.freqThirdQuartile,
          };
        }

        if (!isNaN(aggrData?.value?.featuresAccessed)) {
          intensity = {
            ...intensity,
            category: "Total features accessed",
            value: aggrData?.value?.featuresAccessed,
            median: aggrData?.median?.intensityMedian,
            firstQuart: aggrData?.median?.intensityFirstQuartile,
            thirdQuart: aggrData?.median?.intensityThirdQuartile,
          };
        }

        if (!isNaN(aggrData?.value?.totalTimeSpent)) {
          time = {
            ...time,
            category: "Time spent in minutes in whole app",
            value: aggrData?.value?.totalTimeSpent,
            median: aggrData?.median?.timeMedian,
            firstQuart: aggrData?.median?.timeFirstQuartile,
            thirdQuart: aggrData?.median?.timeThirdQuartile,
          };
        }

        const formatData = (
          items: any[],
          medians: any[],
          key: string,
          labelKey: string,
          valueKey: "intensity" | "timeSpent",
          medianKey: "intensity" | "time"
        ) =>
          items.map((item: any) => {
            const median = medians.find((med: any) => med[key] === item[key]);
            return {
              id: item.id,
              category: item[labelKey]?.name || "",
              value: item[valueKey],
              median: median?.[`${medianKey}Median`],
              firstQuart: median?.[`${medianKey}FirstQuartile`],
              thirdQuart: median?.[`${medianKey}ThirdQuartile`],
            };
          });

        if (eduData?.value?.length) {
          intensity["educationModule"] = formatData(
            eduData?.value || [],
            eduData?.median || [],
            "educationLessonId",
            "educationLesson",
            "intensity",
            "intensity"
          );

          time["educationModule"] = formatData(
            eduData?.value || [],
            eduData?.median || [],
            "educationLessonId",
            "educationLesson",
            "timeSpent",
            "time"
          );
        }

        if (convData?.value?.length) {
          intensity["conversation"] = formatData(
            convData?.value || [],
            convData?.median || [],
            "botId",
            "bot",
            "intensity",
            "intensity"
          );
          time["conversation"] = formatData(
            convData?.value || [],
            convData?.median || [],
            "botId",
            "bot",
            "timeSpent",
            "time"
          );
        }

        if (featData?.value?.length) {
          intensity["other"] = intensity.other = formatData(
            featData?.value || [],
            featData?.median || [],
            "featureId",
            "feature",
            "intensity",
            "intensity"
          );
          time["other"] = formatData(
            featData?.value || [],
            featData?.median || [],
            "featureId",
            "feature",
            "timeSpent",
            "time"
          );
        }

        setData({
          frequency,
          intensity,
          time,
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, [participantId]);

  return !loading ? (
    <>
      {AccMap.map((item) => {
        const rowData = data?.[item];
        return <DataAccordian data={rowData} key={item} label={item} />;
      })}
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  );
};

export default PassiveData;
