import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { StyledTableCell } from "../../../../../Common/styles/table";
import { accTableBodyStyle, accTableStyle } from "../style";
import DataRow from "./DataRow";

const DataAccordian = ({ label, data }: any) => {
  const hasData =
    !isNaN(data?.value) ||
    data?.educationModule?.length > 0 ||
    data?.conversation?.length > 0 ||
    data?.other?.length > 0;

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        boxShadow: "none",
        border: "1px solid #E5E7EB",
        borderRadius: "8px !important",
        mb: 2.5,
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMore sx={{ fontSize: 24 }} />
          </IconButton>
        }
        sx={{
          ".Mui-expanded": {
            m: "12px 0px !important",
          },
        }}
      >
        <Box
          sx={{
            py: 1.5,
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="medium"
            color="secondary"
            mb={0.5}
            sx={{ textTransform: "capitalize" }}
          >
            {label || ""}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 2,
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        {hasData ? (
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ paddingBlock: "20px !important" }}>
                  Category
                </StyledTableCell>
                {/* <StyledTableCell>{`1st consultation (Beg -> 1st)`}</StyledTableCell>
            <StyledTableCell>{`2nd consultation (1st -> 2nd)`}</StyledTableCell>
            <StyledTableCell>
              Last Consultation (Until now)
            </StyledTableCell> */}
                <StyledTableCell>Beginning (Until now)</StyledTableCell>
                <StyledTableCell>Median (IQR)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={accTableBodyStyle}>
              {!isNaN(data?.value) && <DataRow data={data} />}

              {data?.educationModule?.length > 0 && (
                <>
                  <TableRow>
                    <StyledTableCell colSpan={3} sx={accTableStyle}>
                      Education Module
                    </StyledTableCell>
                  </TableRow>
                  {data?.educationModule?.map((row: any) => (
                    <DataRow key={row?.id} data={row} />
                  ))}
                </>
              )}
              {data?.conversation?.length > 0 && (
                <>
                  <TableRow>
                    <StyledTableCell colSpan={3} sx={accTableStyle}>
                      Conversation
                    </StyledTableCell>
                  </TableRow>
                  {data?.conversation?.map((row: any) => (
                    <DataRow key={row?.id} data={row} />
                  ))}
                </>
              )}
              {data?.other?.length > 0 && (
                <>
                  <TableRow>
                    <StyledTableCell colSpan={3} sx={accTableStyle}>
                      Other
                    </StyledTableCell>
                  </TableRow>
                  {data?.other?.map((row: any) => (
                    <DataRow key={row?.id} data={row} />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        ) : (
          <Box
            sx={{
              width: "100%",
              padding: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" color="gray">
              No data available
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DataAccordian;
